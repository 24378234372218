import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { find, map, forEach, uniq, split, toSafeInteger } from 'lodash';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { navigation } from 'app/navigation/navigation';
import { AuthenticationService, UserService } from 'app/services';
import { environment } from 'environments/environment';
import { AppProviders } from 'app/_helpers';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { TranslationsService } from 'app/services/translations.service';
import { MailBoxService } from 'app/services/mail-box.service';
import { AttachmentsService } from 'app/services/attachments.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QuickAddComponent } from 'app/main/master-data/accounts/quick-add/quick-add.component';
import { MatDialog } from '@angular/material/dialog';
import { QuickAddOwnersComponent } from 'app/main/master-data/owners/quick-add-owners/quick-add-owners.component';
import { CrmMenuService } from 'app/services/crm-menu.service';
import { QuickCreateComponent } from 'app/main/properties/quick-create/quick-create.component';
import { CommercialsQuickCreateComponent } from 'app/main/commercials/commercials-quick-create/commercials-quick-create.component';
import { MeetingCreateModalComponent } from 'app/main/activities/meetings/meeting-create-modal/meeting-create-modal.component';
import { TasksCreateModalComponent } from 'app/main/activities/tasks/tasks-create-modal/tasks-create-modal.component';
import { ViewingToursModalComponent } from 'app/main/activities/viewingTours/viewing-tours-modal/viewing-tours-modal.component';
import { FaqsService } from 'app/services/faqs.service';
import { ScreenHelpComponent } from 'app/components/screen-help/screen-help.component';
import { GenerateRegisterTokenComponent } from '../generate-register-token/generate-register-token.component';
import { WebsocketService } from 'app/services/websocket.service';
import { CallTasksCreateModalComponent } from 'app/main/activities/tasks/call-tasks-create-modal/call-tasks-create-modal.component';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy {
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    currentUser: any;
    currentAgency: any;
    env: any;
    commercialMenu: boolean;
    propertyMenu: boolean;
    viewingTourMenu: boolean;
    subscription_paid: boolean = true;

    // Private
    private _unsubscribeAll: Subject<any>;
    userMailBox: any;
    searchValue$ = new Subject<String>();
    img_src: string;
    public unReadCount: string | number = 0;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _crmMenuService: CrmMenuService,
        private _matDialog: MatDialog,
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private _translationsService: TranslationsService,
        private _authenticationService: AuthenticationService,
        private _appProviders: AppProviders,
        private _adapter: DateAdapter<any>,
        private _mailBoxService: MailBoxService,
        private _snackBar: MatSnackBar,
        private router: Router,
        private _faqsService: FaqsService,
        private _activatedRoute: ActivatedRoute,
        private _userService: UserService,
        private _attachmentsService: AttachmentsService,
        private socket: WebsocketService
    ) {
        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon: 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon: 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon: 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];

        this._crmMenuService.commercialModule.subscribe((data: any) => {
            this.commercialMenu = data;
        });
        this._crmMenuService.propertyModule.subscribe((data: any) => {
            this.propertyMenu = data;
        });
        this._crmMenuService.viewingTourModule.subscribe((data: any) => {
            this.viewingTourMenu = data;
        });

        this.languages = this._appProviders.siteLanguages;

        this.navigation = navigation;

        this.currentUser = this._authenticationService.currentUserValue;
        if (this.currentUser && this.currentUser.default_lang && !localStorage.getItem('lang')) {
            const appLang = this.currentUser.default_lang === 'es' ? 'es_AR' : this.currentUser.default_lang;
            this._translateService.use(appLang);
            this._adapter.setLocale(appLang);
        }
        this.currentAgency = this._authenticationService.currentAgencyValue;

        if (this.currentUser?.user_role !== 'admin' && this.currentAgency?.subscription_paid !== undefined && !this.currentAgency?.subscription_paid) {
            this.subscription_paid = false;
        }
        this.env = environment;
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to the config changes
        this._userService.profileImageUrl$.subscribe(imageUrl => {
            if(imageUrl) {
                this.currentUser.user_dp = imageUrl;
                this.getUserProfileImage();
            }
        });

        this.socket.listen('NotificationCount')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(res => {
                this.unReadCount = res;
                if(res > 99){
                    this.unReadCount = "99+"
                }
            });
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });
        // this._authenticationService.currentUser
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe((user) => {
        //         if (user) {
        //             this._mailBoxService.getSettings()
        //                 .pipe(takeUntil(this._unsubscribeAll))
        //                 .subscribe((data) => {
        //                     this.userMailBox = data;
        //                 });
        //         }
        //     });
        // Set the selected language from default languages

        // this.selectedLanguage = find(this.languages, { id: this._translateService.currentLang });

        if (this.currentUser && this.currentUser.default_lang && !localStorage.getItem('lang')) {
            const appLang = this.currentUser.default_lang === 'es' ? 'es_AR' : this.currentUser.default_lang;
            this.selectedLanguage = find(this.languages, { id: appLang });
        } else {
            this.selectedLanguage = find(this.languages, { id: this._translateService.currentLang });
        }

        this.searchValue$.pipe(
            switchMap((search: string) => {
                return this._userService.searchData(search);
            }),
        ).subscribe((data: any) => {
            this._fuseSidebarService.getSidebar('searchPanel').open()
        });
        this.getUserProfileImage();

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value: any): void {
        // Do your search here...
        if (typeof value === 'string') {
            this._userService.searchData(value)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((data) => {
                    this._fuseSidebarService.getSidebar('searchPanel').open();
                });
        }
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        localStorage.setItem('lang', lang.id);

        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);

        // for date picker
        this._adapter.setLocale(lang.id);
    }

    logout(): void {
        this._authenticationService.logout();
    }

    downloadCsv(): void {
        const missingTranslations = JSON.parse(localStorage.getItem('missingTranslations'));
        this._translationsService.getMissing(missingTranslations)
            .subscribe((mt) => {
                const langs = this._translateService.getLangs();

                const data = map(mt, (translations, key) => {
                    const mapData = {} as any;
                    mapData.key = key;
                    forEach(langs, (lang) => {
                        const translation = find(translations, (t) => t.language === lang);
                        mapData[lang] = '';
                        if (translation) {
                            mapData[lang] = translation.value;
                        }
                    });
                    return mapData;
                });

                const options = {
                    showLabels: true,
                    headers: ['key', ...langs],
                };
                // tslint:disable-next-line: no-unused-expression
                new ngxCsv(data, 'missingTranslations', options);
            });

    }

    getUrl(): void {
        console.log(this.router.url);
        const paramsArr = split(this.router.url, '/');
        const arr = [];

        forEach(paramsArr, (value) => {
            if (value.length !== 24 && !toSafeInteger(value)) {
                arr.push(value);
            }
        });

        const total = arr.length;

        let url = '/';
        for (let i = 1; i < (total); i++) {
            if (i !== total - 1) {
                url += arr[i] + '/';
            } else {
                url += arr[i];
            }
        }
        console.log(url);
        this._faqsService.getByUrl(url).subscribe((data: any) => {
            if (data) {
                const dialogRef = this._matDialog.open(ScreenHelpComponent, {
                    panelClass: 'help-screen-dialog',
                    disableClose: false,
                    data: data.content
                });
                dialogRef.afterClosed()
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(result => {
                        if (result) {
                        }
                    });
            } else {
                this._snackBar.open(this._translateService.instant('No FAQs'), this._translateService.instant('Close'), {
                    duration: 2000,
                });
            }

        });
    }

    mailBox(): void {
        if (this.currentUser.user_id) {
            this._mailBoxService
                .getSettings()
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((data) => {
                    this.userMailBox = data;
                    if (this.userMailBox && this.userMailBox.username) {
                        this.router.navigate(['/dashboard/mail/inbox']);
                    } else {
                        this._snackBar.open(
                            this._translateService.instant(
                                'No email connected!'
                            ),
                            this._translateService.instant('Close'),
                            {
                                duration: 2000,
                            }
                        );
                    }
                });
        }
    }

    openAccountsDialog(): void {
        const dialogRef = this._matDialog.open(QuickAddComponent, {
            panelClass: 'event-form-dialog',
            disableClose: false
        });
        dialogRef.afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(result => {
                if (result) {

                }
            });
    }

    openOwnersDialog(): void {
        const dialogRef = this._matDialog.open(QuickAddOwnersComponent, {
            panelClass: 'event-form-dialog',
            disableClose: false
        });
        dialogRef.afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(result => {
                if (result) {
                }
            });
    }

    quickAddProperty(): void {
        const searchDialogRef = this._matDialog.open(QuickCreateComponent, {
            panelClass: 'event-form-dialog',
        });
        searchDialogRef.afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((response: any) => {
                // if (response) {
                //     this.filter = _.pickBy(response.value, _.identity);
                //     this.value.setValue(0);
                // }
            });
    }

    quickAddCommercial(): void {
        const searchDialogRef = this._matDialog.open(CommercialsQuickCreateComponent, {
            panelClass: 'event-form-dialog',
        });
        searchDialogRef.afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((response: any) => {
            });
    }

    addActivities(activity: any): void {
        let dialogRef: any;
        if (activity === 'Meeting') {
            dialogRef = this._matDialog.open(MeetingCreateModalComponent, {
                panelClass: 'event-form-dialog',
                data: {}
            });
        } else if (activity === 'Task') {
            dialogRef = this._matDialog.open(TasksCreateModalComponent, {
                panelClass: 'event-form-dialog',
                data: {}
            });
        } else if (activity === 'Call') {
            dialogRef = this._matDialog.open(CallTasksCreateModalComponent, {
                panelClass: 'event-form-dialog',
                data: {}
            });
        } else if (activity === 'Viewing') {
            dialogRef = this._matDialog.open(ViewingToursModalComponent, {
                panelClass: 'event-form-dialog',
                data: {}
            });
        }

        dialogRef.afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((response: any) => {
                if (response) {

                }
            });

    }

    openTokenDialog() {
        const ref = this._matDialog.open(GenerateRegisterTokenComponent,{
            panelClass:'register-token'
        });
        // ref.
    }

    getUserProfileImage() {
        if(this.currentUser && this.currentUser?.user_id && this.currentUser?.user_dp) {
            this._attachmentsService.getImageURL("users",this.currentUser?.user_id, this.currentUser?.user_dp).subscribe((response: any) => {
                if (response && response.image_src) {
                    this.img_src = response.image_src; // Assign the image source to userProfileSrc
                }
            });
        }
    }

}
