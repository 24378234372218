import { Component, OnInit, ViewEncapsulation, Input, OnDestroy, ViewChild, ElementRef, NgZone } from '@angular/core';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { Subject, forkJoin } from 'rxjs';
import {finalize, take} from 'rxjs/operators';
import { TimeLineService } from 'app/services/time-line.service';
import { takeUntil, distinctUntilChanged, debounceTime, startWith, switchMap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { NgForm, UntypedFormControl, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { AuthenticationService, DropdownsService } from 'app/services';
import { FuseUtils } from '@fuse/utils';
import { concat, forEach, last, map, remove, sortBy, split } from 'lodash';
import { fuseAnimations } from '@fuse/animations';
import { GlobalFuntions } from 'app/_helpers';
import { MeetingCreateModalComponent } from 'app/main/activities/meetings/meeting-create-modal/meeting-create-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { TasksCreateModalComponent } from 'app/main/activities/tasks/tasks-create-modal/tasks-create-modal.component';
import { ViewingToursModalComponent } from 'app/main/activities/viewingTours/viewing-tours-modal/viewing-tours-modal.component';
import { SalesCreateModalComponent } from 'app/main/activities/sales/sales-create-modal/sales-create-modal.component';
import { OffersModalComponent } from 'app/main/activities/offers/offers-modal/offers-modal.component';
import { TimeLineDataService } from './time-line-data.service';
import { TranslateService } from '@ngx-translate/core';
import { GetCommercialReferencePipe } from 'app/pipes/get-commercial-reference.pipe';
import { GetPropertyReferencePipe } from 'app/pipes/get-property-reference.pipe';
import { BaseService } from 'app/_helpers/base/base.service';
import { CrmMenuService } from 'app/services/crm-menu.service';
import { Router } from '@angular/router';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import { AttachmentsService } from 'app/services/attachments.service';
import { ToastrService } from 'ngx-toastr';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WebsocketService } from 'app/services/websocket.service'; // for notification
import * as lamejs from '@breezystack/lamejs';

@Component({
    selector: 'time-line',
    templateUrl: './time-line.component.html',
    styleUrls: ['./time-line.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [GetCommercialReferencePipe, GetPropertyReferencePipe]
})
export class TimeLineComponent implements OnInit, OnDestroy {

    quickSearch = false as boolean;
    comments: UntypedFormGroup;
    properties: any;
    searchProperties$ = new Subject<string>();
    searchCommercial$ = new Subject<string>();
    commercials: any;
    property = false as boolean;
    object = false as boolean;
    searchProjects$ = new Subject<string>();
    projects: any;
    userAgency: any;
    timeLine: any;
    type: UntypedFormControl = new UntypedFormControl('');
    searchInput: UntypedFormControl = new UntypedFormControl('');
    filteredTimeLine: any;
    env: { production: boolean; hmr: boolean; yiiUrl: string; apiUrl: string; cdnUrl: string; ibizaAgency: string };
    loader: boolean;
    emailLoader: boolean;
    messageLoader: boolean;
    closeButton = false;
    dialogRef: any;
    modelData: any;
    model: any;
    currentLang: any;
    relatedTo: any;
    accountsOrOwnersTimeline: Boolean = false;
    // #2683
    showingActivities: string = "true";
    showingActivitiesColor: Boolean = true;
    showingEmailsColor: Boolean = false;
    showingMessagesColor: Boolean = false;
    accountsMessagesTimeline: Boolean = false;
    meeTimeline: Boolean = false;
    messageTimeLine: any;
    expandedMessageTimeline = [];
    expandedMessageTimelineLoader: Boolean;
    currencySign : any;
    emailTimeLine: any;
    filteredEmailTimeLine: any;
    basicTimeLine: any;
    filteredBasicTimeLine: any;
    commentUserData: any;
    userRole: any;
    BadgeHidden: boolean =  true;
    propMesg = [];

    @ViewChild('messagesContainer', { static: false }) private messagesContainer: ElementRef;
    @ViewChild('replyForm', { static: false })
    private _replyForm: NgForm;

    @ViewChild('replyInput', { static: false })
    private _replyInput: ElementRef;

    private _unsubscribeAll: Subject<any>;
    propertyModule: any;

    isRecording = false;
    mediaRecorder: any;
    audioChunks: any[] = [];
    audioURL: string | null = null;
    waveList: number[] = [];
    audioBlob: Blob;

    showMessageTab: boolean = false;
    messageType: String = 'text';
    attachments: string[] = [];
    currentOffice: any;
    // this roles see all other role chat like agent
    rolePermissions = ["agency_admin","admin","agency_manager"];

    constructor(
        public _timeLineData: TimeLineDataService,
        private _translateService: TranslateService,
        public _fuseSidebarService: FuseSidebarService,
        public _timeLineService: TimeLineService,
        private formBuilder: UntypedFormBuilder,
        private _globalFunction: GlobalFuntions,
        private _dropdownsService: DropdownsService,
        public _authenticationService: AuthenticationService,
        private _matDialog: MatDialog,
        private _getCommercialReference: GetCommercialReferencePipe,
        private _getPropertyReference: GetPropertyReferencePipe,
        private _baseService: BaseService,
        private _crmMenuService: CrmMenuService,
        private router: Router,
        private _ngZone: NgZone,
        private _attachmentService: AttachmentsService,
        private _toaster: ToastrService,
        private _snackBar: MatSnackBar,
        private socket: WebsocketService,

        ) {
            this.modelData = this._timeLineData.modelDataValue;
            this.relatedTo = this._timeLineData.relatedToValue;
            this.currentLang = this._translateService.currentLang === 'es' ? 'es_AR' : this._translateService.currentLang;
            this.userAgency = _authenticationService.currentAgencyValue;
            this.currentOffice = _authenticationService.currentOfficeValue;
            this.env = environment;
            this._unsubscribeAll = new Subject();
            this.propertyModule = this._crmMenuService.isPropertyModule;            
            this.userRole = this._authenticationService.currentUserValue;
            this.waveList = Array(20).fill(0);
        }
        
        @ViewChild('autosize') autosize: CdkTextareaAutosize;
        triggerResize() {
            this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
        }
    

    ngOnInit(): void {

        this.showMessageTab = this.env.ibizaAgency == this.userAgency._id ? true : false;
        this.loadData();
        this.comments = this.formBuilder.group({
            related_to: { value: this.userAgency.commercial_properties ? 'Object' : 'Property', disabled: false },
            related_to_id: { value: '', disabled: false },
            activity_type: { value: '', disabled: false }
        });
        if (this.comments.get('related_to').value == 'Object') {
            this.object = true;
        } else if (this.comments.get('related_to').value == 'Property') {
            this.property = true;
        }
        if (this.router.url.split('/').includes("boats") || this.router.url.split('/').includes("moorings"))
            this.comments.get('related_to').setValue(this.router.url.split('/').includes("boats") ? 'Boat' : 'Mooring');
        
        this.searchInput.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                distinctUntilChanged()
            )
            .subscribe(searchText => {
                this.filteredTimeLine = FuseUtils.filterArrayByString(this.timeLine, searchText);
            });
        if(this.propertyModule) {
            this.searchProperties$
            .pipe(
                startWith(''),
                takeUntil(this._unsubscribeAll),
                switchMap((search: string) => {
                    return this._dropdownsService.getProperty(search);
                }),
            )
            .subscribe((data: any) => {
                forEach(data.body, (value) => {
                    value.label = value.reference + ' - ' + value.agency_reference + ' - ' + value.type_one + ' - ' + value.location;
                });
                this.properties = data.body;
            });
        }

        this.searchCommercial$
        .pipe(
            startWith(''),
            switchMap((search: string) => {
                const searchFilter = {} as any;
                    if (search) {
                        const queryOr = [];
                        queryOr.push({ reference: Number(search) });
                        queryOr.push({ external_reference: { $regex: '.*' + search + '.*', $options: 'i' } });
                        queryOr.push({ other_reference: { $regex: '.*' + search + '.*', $options: 'i' } });
                        searchFilter.$or = queryOr;
                    }
                    return this._dropdownsService.getCommercialProperties(searchFilter);
                })
            )
            .subscribe((data: any) => {
                const commercialObjects = map(data.body, (property) => {
                    property.label = property.reference;
                    if (property.hasOwnProperty('property_type_one') && property?.property_type_one?.value) {
                        property.label = property.label + ' - ' + property.property_type_one.value[this.currentLang];
                    }
                    if (property.hasOwnProperty('property_location') && property?.property_location?.value) {
                        property.label = property.label + ' - ' + property.property_location.value[this.currentLang];
                    }
                    return property;
                });
                this.commercials = commercialObjects;
            });

        this.searchProjects$
            .pipe(
                startWith(''),
                takeUntil(this._unsubscribeAll),
                switchMap((search: string) => {
                    return this._dropdownsService.getProject(search);
                }),
            )
            .subscribe((data: any) => {
                forEach(data.body, (value) => {
                    // tslint:disable-next-line: max-line-length
                    value.label = (value._id ? (this.userAgency.agency_reference + '-') : '') + value.reference + ' / ' + value.user_reference + ' / ' + value.agency_reference;
                });
                this.projects = data.body;
            });
            
            this.comments.get('related_to').valueChanges.subscribe((data: any) => {
            if (data === 'Property') {
                this.property = true;
                this.object = false;
            } else if (data === 'Object') {
                this.property = false;
                this.object = true;
            } else {
                this.property = false;
                this.object = false;
            }
        });

        this.socket.listen('commentStatusUpdated').pipe(takeUntil(this._unsubscribeAll)).subscribe(updatedComment => {
            const commentIndex = this.messageTimeLine.findIndex(c => (c?.wamid === updatedComment?.wamid));
            if (commentIndex !== -1) {
              this.messageTimeLine[commentIndex] = updatedComment;
            }else {
              this.messageTimeLine.push(updatedComment)
            }
        })
    }

    loadData(): void {
        this.timeLine = [];
        if (this.relatedTo === 'agencies' && this.modelData) {
            this.model = 'Agencies';
            this.agenciesTimeline();
        }
        if (this.relatedTo === 'sales' && this.modelData) {
            this.model = 'Accounts';
            this.salesTimeline();
        }
        if (this.relatedTo === 'account' && this.modelData) {
            this.model = 'Accounts';
            this.accountsOrOwnersTimeline = true;
            this.accountsMessagesTimeline = true;
            this.accountTimeline();
        }
        if (this.relatedTo === 'company' && this.modelData) {
            this.model = 'Company';
            this.companyTimeline();
        }
        if (this.relatedTo === 'activities' && this.modelData) {
            if (this.modelData.type === 'viewing') {
                this.model = 'Viewings';
            } else {
                this.model = 'Activities';
            }
            this.activitiesTimeline();
        }
        if (this.relatedTo === 'owner' && this.modelData) {
            this.model = 'Owners';
            this.accountsOrOwnersTimeline = true;
            this.ownerTimeline();
        }
        if (this.relatedTo === 'accountNotes' && this.modelData) {
            this.model = 'Accounts';
            this.closeButton = true;
            this.accountsOrOwnersTimeline = true;
            this.accountTimeline();
        }
        if (this.relatedTo === 'ownerNotes' && this.modelData) {
            this.model = 'Owners';
            this.closeButton = true;
            this.accountsOrOwnersTimeline = true;
            this.ownerTimeline();
        }
        if (this.relatedTo === 'Commercials' && this.modelData) {
            this.model = 'Commercial-Properties';
            this.commercialTimeline();
        }
        if (this.relatedTo === 'mooringPrivateInfo' && this.modelData) {
            this.model = 'Mooring-Properties';
            this.closeButton = true;
            this.boatMooringTimeline();
        }
        if (this.relatedTo === 'mooring' && this.modelData) {
            this.model = 'Mooring-Properties';            
            this.boatMooringTimeline();
            this.type.setValue('Note');
        }
        if (this.relatedTo === 'property' && this.modelData) {
            this.model = 'Properties';
            this.propertyTimeline();
            this.type.setValue('Note');
        }
        if (this.relatedTo === 'propertyPrivateinfo' && this.modelData) {
            this.model = 'Properties';
            this.closeButton = true;
            this.propertyTimeline();
        }
        if (this.relatedTo === 'sequence' && this.modelData) {
            this.model = 'Sequences';
            this.sequenceTimeline();
        }
        if (this.relatedTo === 'RentalContracts' && this.modelData) {
            this.model = 'RentalContracts';
            this.activitiesTimeline();
        }
    }

    openDialog(type: string, id: string, dateTime?: any): void {
        if (type === 'Meeting') {
            this.dialogRef = this._matDialog.open(MeetingCreateModalComponent, {
                panelClass: 'event-form-dialog',
                data: {
                    relatedTo: 'update',
                    _id: id
                }
            });
        } else if (type === 'Task') {
            this.dialogRef = this._matDialog.open(TasksCreateModalComponent, {
                panelClass: 'event-form-dialog',
                data: {
                    relatedTo: 'update',
                    _id: id
                }
            });
        } else if (type === 'Viewing') {
            this.dialogRef = this._matDialog.open(ViewingToursModalComponent, {
                panelClass: 'event-form-dialog',
                data: {
                    relatedTo: 'update',
                    _id: id
                }
            });
        } else if (type === 'Sale') {
            this.dialogRef = this._matDialog.open(SalesCreateModalComponent, {
                panelClass: 'event-form-dialog',
                data: {
                    relatedTo: 'update',
                    _id: id
                }
            });
        } else if (type === 'Offer') {
            this.dialogRef = this._matDialog.open(OffersModalComponent, {
                panelClass: 'event-form-dialog',
                data: {
                    relatedTo: 'update',
                    _id: id
                }
            });
        }
        else { return; }

        this.dialogRef.afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((response: any) => {
                if (response) {
                }
            });
    }

    submit(): void {
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // Method to scroll to the bottom of the message container
    scrollToBottom(): void {
        if (this.messagesContainer) {
            this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
        }
    }

    agenciesTimeline(): void {
        this.loader = true;
        this._baseService.get(`comments/agencies-timeline?model=${this.model}&model_id=${this.modelData._id}&expand=attachments,user_data,created_user`)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                this.timeLine = data;
                this.filteredTimeLine = data;
                this.loader = false;
            });

    }

    salesTimeline(): void {
        this.loader = true;
        this._timeLineService.getAccountTimeline(this.model, this.modelData.account)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                this.timeLine = data;
                this.filteredTimeLine = data;
                this.loader = false;
            });
        this._timeLineService.getAccountTimelineEmails(this.model, this.modelData.account)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                this.emailTimeLine = data;
                this.filteredEmailTimeLine = data;
            });
    }

    showActivities(status): void {
        this.showingActivities = status;
        if (status === 'true') {
            this.showingActivitiesColor = true;
            this.showingEmailsColor = false;
            this.showingMessagesColor = false;

            this.timeLine = this.basicTimeLine;
            this.filteredTimeLine = this.filteredBasicTimeLine;
        } else if(status === 'false') {
            this.showingActivitiesColor = false;
            this.showingEmailsColor = true;
            this.showingMessagesColor = false;

            this.timeLine = this.emailTimeLine;
            this.filteredTimeLine = this.filteredEmailTimeLine;
        } else if(status === 'message') {
            this.showingActivitiesColor = false;
            this.showingEmailsColor = false;
            this.showingMessagesColor = true;
            setTimeout(() => {
                this.scrollToBottom();
            }, 0); // Timeout to ensure that the view is fully rendered before scrolling
            // this.timeLine = this.messageTimeLine;
            // this.filteredTimeLine = null;
            // this.accountTimeline();
        }
    }

    accountTimeline(): void {
        this.loader = true;
        this.emailLoader = true;
        // this.messageLoader = true;
        // WhatsApp timeline

        this._timeLineService.getAccountTimeline(this.model, this.modelData._id, '',  this.currentOffice?._id).pipe(takeUntil(this._unsubscribeAll))
        .subscribe(data => {
            if(data){
                this.activitiesFormattedData(data);
            }
            this.loader = false;
        });
        this._timeLineService.getAccountTimeline(this.model, this.modelData._id, '',  '', 'Whatsapp/Message').pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
            if(data){
                this.loader = false;
                this.timeLine = this.basicTimeLine = data;
                const filterData = forEach(data, (value: any) => {
                    if (value && value.activity_type) {
                        value.activity_type = this._translateService.instant(value.activity_type);
                    }
                    if (value && value.comment) {
                        const dates = new Date(value.created_at);
                        const NewDate = dates.getDate() + '/' + (dates.getMonth() + 1) + '/' + dates.getFullYear();
                        value.customComment =
                        `<b> ${this._translateService.instant('Account')}</b> : ${value?.user_data?.full_name} 
                        <br>
                        <b> ${this._translateService.instant('Date')}</b> : ${NewDate ? NewDate : ''} <br>
                        <b> ${this._translateService.instant('Time')}</b> : ${value.created_at ? (dates.getHours() + ':' + dates.getMinutes()) : ''} 
                        <br> ${value.comment ? value.comment : ''}
                        <br> ${(value.email_data && value.email_data.content) ? value.email_data.content : ''}
                        ` ;
                    }
                });
                this.filteredTimeLine = this.filteredBasicTimeLine = filterData;
                this.loader = false;
                if (this.modelData.whatsapp && this.showingActivities != 'message') {
                    this.comments.get('activity_type').setValue('Whatsapp/SMS');
                }
            }
        });

        // Email timeline
        this._timeLineService.getAccountTimelineEmails(this.model, this.modelData._id).pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
                this.emailTimeLine = data;
                this.filteredEmailTimeLine = forEach(data, (value: any) => {
                    if (value && value?.activity_type) {
                        value.activity_type = this._translateService.instant(value?.activity_type);
                    }
                    if (value && value?.comment) {
                        const dates = new Date(value?.created_at);
                        const NewDate = dates.getDate() + '/' + (dates.getMonth() + 1) + '/' + dates.getFullYear();
                        value.customComment =
                            `<b> ${this._translateService.instant('Account')}</b> : 
                         ${value?.user_data?.full_name} 
                        <br>
                        <b> ${this._translateService.instant('Date')}</b> : ${NewDate ? NewDate : ''} <br>
                        <b> ${this._translateService.instant('Time')}</b> : ${value.created_at ? (dates.getHours() + ':' + dates.getMinutes()) : ''} 
                        <br> ${value.comment ? value.comment : ''}
                        <br> ${(value.email_data && value.email_data.content) ? value.email_data.content : ''}
                        ` ;
                    }
                });
                this.emailLoader = false;
                if (this.showingEmailsColor) {
                    this.timeLine = this.emailTimeLine;
                    this.filteredTimeLine = this.filteredEmailTimeLine;
                }
        });

        // Message timeline only for ibizaAgency
        if(this.showMessageTab) { // for ibizaAgency
            this._timeLineService.getAccountTimelineMessages("Properties", this.modelData._id).pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data : any) => {
                if(data) {
                    this.messageTimeLine = data;
                    this.messageLoader = false;
                    this.messageTimeLine = forEach(data, (value: any) => { 
                        if (value && value.currency) {
                            value.currency = this.checkCurrencySign(value);
                        }
                    });
                }
            });
            this._timeLineService.getAccountExpandedPropertiesTimelineStatus(this.modelData._id).pipe(takeUntil(this._unsubscribeAll))
            .subscribe((statusData: any)=> {
                if(statusData) {
                    const unSeenStatues = [];
                    if (statusData?.comments) {
                        statusData.comments.forEach((msg) => {
                            if(msg?.status === 'Unseen') {
                                unSeenStatues.push(msg?.status)
                            }
                        });
                    }
                    if(unSeenStatues.length > 0) {
                        this.BadgeHidden = false;
                    }else {
                        this.BadgeHidden = true;
                    }
                }
            });     
        }
    }

    async activitiesFormattedData(data: any) {
        this.messageTimeLine = [];
        for (const value of data) {
            const activity = await this.formatActivity(value);
            const activityType = activity?.activity_type_without_translation === 'Whatsapp/Message' ? 'Whatsapp/Message' : 'Whatsapp/Message';
            // Separate the objects based on activity_type
            if (activityType === 'Whatsapp/Message') {
                value.show_message = false;
                if (this.rolePermissions.includes(this.userRole?.user_role)) { // if user is an admin
                  value.show_message = true;
                } else if (value.assigned_to_ids?.includes(this.userRole._id)) { // Check assigned_to_ids exists and includes the current user's ID
                  value.show_message = true;
                }
                this.messageTimeLine.push(value);
            }
        }
        this.messageTimeLine = sortBy(this.messageTimeLine, 'created_at');
    }

    formatActivity(activity) {
        if (activity?.activity_type) {
            activity.activity_type_without_translation = activity.activity_type;
            activity.activity_type = this._translateService.instant(activity.activity_type);
        }
        if (activity?.comment) {
            const dates = new Date(activity.created_at);
            const NewDate = `${dates.getDate()}/${dates.getMonth() + 1}/${dates.getFullYear()}`;
            activity.customComment =
                `<b>${this._translateService.instant('Account')}</b> : ${activity?.user_data?.full_name} 
            <br>
            <b>${this._translateService.instant('Date')}</b> : ${NewDate || ''} <br>
            <b>${this._translateService.instant('Time')}</b> : ${activity.created_at ? `${dates.getHours()}:${dates.getMinutes()}` : ''} 
            <br> ${activity.comment || ''}
            <br> ${(activity.email_data && activity.email_data.content) ? activity.email_data.content : ''}`;
        }
        if(activity?.conversation?.expiration_timestamp){
            const dateObject = new Date(activity?.created_at);
            const timestampInSeconds = Math.floor(dateObject.getTime()/1000);
            // const currentTimestamp = Math.floor(Date.now() / 1000);
            const differenceInSeconds = Number(activity?.conversation?.expiration_timestamp) - timestampInSeconds;
            
            // Calculate the hours and minutes from the difference
            const hours = Math.floor(differenceInSeconds / 3600);
            const minutes = Math.floor((differenceInSeconds % 3600) / 60);
            activity.expires = `${hours}h ${minutes}m`;
        }
        return activity;
    }

    activitiesTimeline(): void {
        this.loader = true;
        this._timeLineService.getActivitiesTimeline(this.model, this.modelData._id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                this.timeLine = data;
                this.filteredTimeLine = data;
                this.loader = false;
            });
    }

    companyTimeline(): void {
        this.loader = true;
        this._timeLineService.getCompaniesTimeline(this.model, this.modelData._id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                this.timeLine = data;
                this.filteredTimeLine = data;
                this.loader = false;
            });
    }

    ownerTimeline(): void {
        this.loader = true;
        this.emailLoader = true;
        this._timeLineService.getOwnerTimeline(this.model, this.modelData._id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                this.timeLine = this.basicTimeLine = data;
                const filterData = forEach(data, (value: any) => {
                    if (value && value.activity_type) {
                        value.activity_type = this._translateService.instant(value.activity_type);
                    }
                    if (value && value.comment) {
                        const dates = new Date(value.created_at * 1000);
                        const NewDate = dates.getDate() + '/' + (dates.getMonth() + 1) + '/' + dates.getFullYear();
                        value.customComment =
                            `<b> ${this._translateService.instant('Owner')}</b> : 
                         ${this.modelData?.full_name} 
                        <br>
                        <b> ${this._translateService.instant('Date')}</b> : ${NewDate ? NewDate : ''} <br>
                        <b> ${this._translateService.instant('Time')}</b> : ${value.created_at ? (dates.getHours() + ':' + dates.getMinutes()) : ''} 
                        <br> ${value.comment ? value.comment : ''}
                        ` ;
                    }
                });
                this.filteredTimeLine = this.filteredBasicTimeLine = filterData;
                this.loader = false;
                if (this.modelData.whatsapp) {
                    this.comments.get('activity_type').setValue('Whatsapp/SMS');
                }
            });
        this._timeLineService.getOwnerTimelineEmails(this.model, this.modelData._id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                this.emailTimeLine = data;
                this.filteredEmailTimeLine = forEach(data, (value: any) => {
                    if (value && value.activity_type) {
                        value.activity_type = this._translateService.instant(value.activity_type);
                    }
                    if (value && value.comment) {
                        const dates = new Date(value.created_at);
                        const NewDate = dates.getDate() + '/' + (dates.getMonth() + 1) + '/' + dates.getFullYear();
                        value.customComment =
                            `<b> ${this._translateService.instant('Account')}</b> : 
                         ${value?.user_data?.full_name} 
                        <br>
                        <b> ${this._translateService.instant('Date')}</b> : ${NewDate ? NewDate : ''} <br>
                        <b> ${this._translateService.instant('Time')}</b> : ${value.created_at ? (dates.getHours() + ':' + dates.getMinutes()) : ''} 
                        <br> ${value.comment ? value.comment : ''}
                        <br> ${(value.email_data && value.email_data.content) ? value.email_data.content : ''}
                        ` ;
                    }
                });
                this.emailLoader = false;
                if (this.showingEmailsColor) {
                    this.timeLine = this.emailTimeLine;
                    this.filteredTimeLine = this.filteredEmailTimeLine;
                }
            });
    }

    propertyTimeline(): void {
        this.loader = true;
        this._timeLineService.getPropertyTimeline(this.model, this.modelData._id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                this.timeLine = data;
                // this.filteredTimeLine = data;
                this.filteredTimeLine = forEach(data, (value: any) => {
                    if (value && value.comment) {
                        const dates = new Date(value.date_time_unix * 1000);
                        const NewDate = dates.getDate() + '/' + (dates.getMonth() + 1) + '/' + dates.getFullYear();
                        value.customComment =
                            `<b> ${this._translateService.instant('Property')}</b> : 
                        ${this._getPropertyReference.transform(this.modelData)} ${this.modelData?.type_one_name ? this.modelData?.type_one_name : ''} 
                        ${this._translateService.instant('in')} ${this.modelData?.location_name ? this.modelData?.location_name : ''}
                        <br>
                        <b> ${this._translateService.instant('Date')}</b> : ${NewDate ? NewDate : ''} <br>
                        <b> ${this._translateService.instant('Time')}</b> : ${value.date_time ? (dates.getHours() + ':' + dates.getMinutes()) : ''} 
                        <br> ${value?.comment}
                        ` ;
                    }
                });
                this.loader = false;
            });
    }

    commercialTimeline(): void {
        this.loader = true;
        this._timeLineService.getCommercialTimeline(this.model, this.modelData._id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                this.timeLine = data;
                // this.filteredTimeLine = data;
                this.filteredTimeLine = forEach(data, (value: any) => {
                    if (value && value.comment) {
                        const dates = new Date(value.date_time_unix * 1000);
                        const NewDate = dates.getDate() + '/' + (dates.getMonth() + 1) + '/' + dates.getFullYear();
                        value.customComment =
                            `<b> ${this._translateService.instant('Property')}</b> : 
                         ${this._getCommercialReference.transform(this.modelData)} ${this.modelData?.type_one_value ? this.modelData?.type_one_value[this.currentLang] : ''} 
                         ${this._translateService.instant('in')} ${this.modelData?.location_value ? this.modelData?.location_value[this.currentLang] : ''}
                        <br>
                        <b> ${this._translateService.instant('Date')}</b> : ${NewDate ? NewDate : ''} <br>
                        <b> ${this._translateService.instant('Time')}</b> : ${value.created_at ? (dates.getHours() + ':' + dates.getMinutes()) : ''} 
                        <br> ${value?.comment}
                        ` ;
                    }
                });
                this.loader = false;
            });
    }

    boatMooringTimeline(): void {
        this.loader = true;
        this._timeLineService.getBoatMooringTimeline(this.model, this.modelData._id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                this.timeLine = data;
                this.filteredTimeLine = forEach(data, (value: any) => {
                    if (value && value.comment) {
                        const dates = new Date(value.date_time_unix * 1000);
                        const NewDate = dates.getDate() + '/' + (dates.getMonth() + 1) + '/' + dates.getFullYear();
                        const type = this.router.url.split('/').includes("boats") ? 'Boat' : 'Mooring';
                        value.customComment =
                            `<b> ${this._translateService.instant(type)}</b> : 
                         ${this._getCommercialReference.transform(this.modelData)} ${this.modelData?.type_one_value ? this.modelData?.type_one_value[this.currentLang] : ''} 
                         ${this._translateService.instant('in')} ${this.modelData?.location_value ? this.modelData?.location_value[this.currentLang] : ''}
                        <br>
                        <b> ${this._translateService.instant('Date')}</b> : ${NewDate ? NewDate : ''} <br>
                        <b> ${this._translateService.instant('Time')}</b> : ${value.created_at ? (dates.getHours() + ':' + dates.getMinutes()) : ''} 
                        <br> ${value?.comment}
                        ` ;
                    }
                });
                this.loader = false;
            });
    }

    sequenceTimeline(): void {
        this.loader = true;
        this._timeLineService.getSequenceTimeline(this.model, this.modelData._id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                this.timeLine = data;
                this.filteredTimeLine = data;
                this.loader = false;
            });
    }

    getTimeline(): void {
        this.loader = true;
        this._baseService.get(`comments?model=${this.model}&model_id=${this.modelData._id}&expand=attachments,user_data,created_user`)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                this.timeLine = data;
                this.filteredTimeLine = data;
                this.loader = false;
            });
    }

    reply(event: any, modelName='', modelId='', msgIndex=''): void {
        const currentUser = this._authenticationService.currentUserValue;
        event.preventDefault();

        /**
         * https://gitlab.optimasit.com/arsl/optima-crm-v2/-/issues/2963
         * as we using different controls to send message 
         * that why we use here setSendMessage
         */
        let setSendMessage = null;
        setSendMessage = this._replyForm?.form?.value?.message;

        if((typeof(this._replyForm.form.value.message0) == 'undefined') && setSendMessage == null) {
            setSendMessage = this.propMesg[msgIndex];
        }
        if(typeof(setSendMessage) == 'undefined' || setSendMessage == null) {
            setSendMessage = this._replyForm.form.value.message0;
        }

        if (!setSendMessage) {
            return;
        }
        const m_name = modelName ? modelName : this.model;
        const m_id = modelId ? modelId : this.modelData._id; 
        const message = {
            message: setSendMessage,
            activity_type: this.comments.get('activity_type').value,
            related_to: this.comments.get('related_to').value,
            related_to_id: this.comments.get('related_to_id').value,
            comment: setSendMessage,
            created_by: currentUser.user_id,
            model: m_name,
            model_id: m_id,
            user: currentUser.full_name,
            user_id: currentUser.user_id,
            users: []
        };
        if (this.relatedTo === 'sales') {
            message.model_id = this.modelData.account;
        }

        // for message tab check
        if(this.showingMessagesColor) {
            Object.assign(message, {account_id: this.modelData._id});
            Object.assign(message, {assigned_to_id: currentUser.user_id});  // as assigned user login to send reply
            Object.assign(message, {status: "Seen"});
            if(message?.related_to_id !='') {
                Object.assign(message, {related_to_id: modelId});
            }
        }
        this.propMesg[msgIndex] = '';
        this._replyForm.reset();
        this.loader = true;
        this.messageLoader = true;
        this._timeLineService.addComment(this._globalFunction.cleanObject(message))
            .subscribe(() => {
                if (this.relatedTo === 'agencies') {
                    this.agenciesTimeline();
                }
                if (this.relatedTo === 'sales') {
                    this.salesTimeline();
                }
                if (this.relatedTo === 'account') {
                    this.accountTimeline();
                }
                if (this.relatedTo === 'company') {
                    this.companyTimeline();
                }
                if (this.relatedTo === 'owner') {
                    this.ownerTimeline();
                }
                if (this.relatedTo === 'ownerNotes') {
                    this.ownerTimeline();
                }
                if (this.relatedTo === 'accountNotes') {
                    this.accountTimeline();
                }
                if (this.relatedTo === 'property' || this.relatedTo === 'propertyPrivateinfo') {
                    this.propertyTimeline();
                }
                if (this.relatedTo === 'sequence') {
                    this.sequenceTimeline();
                }
                if (this.relatedTo === 'activities') {
                    this.activitiesTimeline();
                }
                if (this.relatedTo === 'Commercials') {
                    this.commercialTimeline();
                }
                if (this.relatedTo === 'mooringPrivateInfo' || this.relatedTo === 'mooring') {
                    this.boatMooringTimeline();
                }
            });

        if (this.comments.get('activity_type').value === 'Whatsapp/SMS' && this.modelData.mobile_phone) {
            window.open(`https://api.whatsapp.com/send?phone=${this.modelData.mobile_phone}&text=${message.comment}&source=&data=`, `_blank`);
        }
        if (this.comments.get('activity_type').value === 'Whatsapp/SMS' && this.modelData.mobile) {
            window.open(`https://api.whatsapp.com/send?phone=${this.modelData.mobile}&text=${message.comment}&source=&data=`, `_blank`);
        }
    }

    checkCurrencySign(property){
        this.currencySign = ' €';
        if (property?.currency) {
            if (property.currency === 'GBP') {
              this.currencySign = ' £';
            } else if (property.currency === 'USD') {
              this.currencySign = ' $';
            } else if(property.currency === 'EUR'){
              this.currencySign = ' €';
            }
        }
        return this.currencySign;
    }

    expandedRows(expandedProp, i) {
        this.expandedMessageTimelineLoader = true;
        this._timeLineService.getAccountExpandedPropertiesTimeline(expandedProp._id, this.modelData._id).pipe(takeUntil(this._unsubscribeAll))
        .subscribe((data : any) => {
            if(data) {
                this.expandedMessageTimeline[i] = data.comment;
                this.commentUserData = data.user_data;
                let comment_ids_array=[];
                data.comment.forEach(commentIds => {
                    if(commentIds.status === 'Unseen') {
                        comment_ids_array.push(commentIds._id.$oid)
                    }
                });
                this.expandedMessageTimelineLoader = false;
                if(comment_ids_array.length > 0) {
                    this._timeLineService.getAccountTimelineUpdateStatuses(comment_ids_array).pipe(takeUntil(this._unsubscribeAll)).subscribe((updateStatueResp : any) => {
                        if(updateStatueResp) {
                            this.BadgeHidden = updateStatueResp.body
                        }
                    });
                }
            }
        });
    }

    // handel WhatApp logic here

    sendMessage(event: any) {
        event.preventDefault();
        this.messageLoader = true;
        const mobilePhone = this.modelData?.mobile_phone;
        if (mobilePhone && Array.isArray(mobilePhone) && mobilePhone.length) {
            let message = {
                "content" : this._replyForm?.form?.value?.message,
                "template" : this.messageType // template can be text, document, image or template
            };
            if(this.attachments.length) {
                message["attachments"] = this.attachments;
            }
            const postData = {
                account: {
                    modelId: this.modelData?._id,
                    agency: this.userAgency?._id,
                    user_office: this.currentOffice?._id,
                    userId: this.userRole?._id,
                    assigned_to_ids: this.modelData?.assigned_to_ids,
                    user_data: {
                        user_name: this.userRole?.user_name,
                        first_name: this.userRole?.first_name,
                        last_name: this.userRole?.last_name,
                        full_name: this.userRole?.full_name,
                        user_email: this.userRole?.user_email,
                        mobile_phone: this.userRole?.user_phone
                    }
                },
                'to': mobilePhone,
                'message': message
            };
            this.messageLoader = true;
            this._baseService.post('external-service/wa-message', postData, 'nodejs').subscribe((data: any) => {
                this.messageLoader = false;
                if(data){
                    if(data?.status == 200) {
                        // this.messageTimeLine.unshift(this.formatActivity(data.data));
                        this._replyForm.resetForm();
                        this.attachments = [];
                        this.messageType = "text";
                    }else {
                        this._toaster.error(this._translateService.instant(data.message));
                    }
                }
            });
        } else {
            this._toaster.warning(this._translateService.instant('Make sure mobile phone exists.'),this._translateService.instant('warning'));
        }
    }

    currentDate(){
        var now = new Date();
        var day = ("0" + now.getDate()).slice(-2);
        var month = ("0" + (now.getMonth() + 1)).slice(-2);
        return month + "-" + day + "-" + now.getFullYear();
    }

    removeAttachment(file: any): void {
        remove(this.attachments, (el: any) => {
            return el === file;
        });
    }

    onSelectFile(files: FileList, attachmentType): void {
        let size = 0;
        let renameFiles = []
        forEach(files, (file: File) => {
            size = file.size + size;
            renameFiles.push(this.renameFileWithTimestamp(file))
        });

        let allowedSize = 0;
        if(attachmentType === 'document'){
            allowedSize = 104857600 // whatsapp limit to upload max size document is 100MB
        }else{
            allowedSize =  16777216 // whatsapp limit to upload max size image is 16MB
        }

        this.messageType = attachmentType;
        if (size <= allowedSize) { 
            const uploader = [];
            this.messageLoader = true;
            forEach(renameFiles, (file: File) => {
                uploader.push(this._attachmentService.mailAttachment(file, 'whatsapp', this.modelData?._id));
            });
            forkJoin(uploader)
                .pipe(
                    takeUntil(this._unsubscribeAll),
                    finalize(() => this.messageLoader = false) 
                )
                .subscribe((data: any) => {
                    const attachments = map(data, (url) => {
                        return this.env.cdnUrl+'whatsapp/'+this.modelData?._id+'/'+last(split(url, '/'));
                    });
                    this.attachments = concat(this.attachments, attachments);
                }, () => {});
        } else {
            this._snackBar.open(this._translateService.instant(`${attachmentType} size must not be greater than ${allowedSize/1048576}MB`), this._translateService.instant('Close'), {
                duration: 3000,
            });
            forEach(renameFiles, (file: File) => {
                this.removeAttachment(file);
            });
        }
    }

    renameFileWithTimestamp(file) {
        const timestamp = Math.round(Date.now() / 1000); // Get current timestamp in milliseconds
        const extension = file.name.split('.').pop(); // Extract file extension
        const baseName = file.name.replace(/\.[^/.]+$/, ""); // Remove existing extension
        const newName = `${baseName}_${timestamp}.${extension}`; // Create new name with timestamp
    
        return new File([file], newName, { type: file.type });
    }

    startRecording() {
        this.isRecording = true;
        this.audioChunks = [];
        navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
            const options = { mimeType: 'audio/webm; codecs=opus' };
            this.mediaRecorder = new MediaRecorder(stream, options);
            this.mediaRecorder.start();
            this.mediaRecorder.ondataavailable = (event: any) => {
                if (event.data.size > 0) {
                    this.audioChunks.push(event.data);
                }
            };
            this.mediaRecorder.onstop = async () => {
                const audioBlob = new Blob(this.audioChunks, { type: 'audio/webm' });
                this.audioURL = URL.createObjectURL(audioBlob);
                // the package we use on frontend is first converted into WAV and then MP3
                const wavAudioBlob = await this.convertToWav(audioBlob);
                // Convert WAV to MP3
                this.audioBlob = await this.convertWavToMp3(wavAudioBlob);
            };
        }).catch(error => {
            console.error('Error accessing microphone', error);
            this.isRecording = false;
        });
    }
    stopRecording() {
        this.isRecording = false;
        if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
          this.mediaRecorder.stop();
        }
    }
    
    discardVoiceMessage() {
        this.audioURL = null;
        this.audioChunks = [];
        this.isRecording = false;
    }

    convertWavToMp3(wavBlob: Blob): Promise<Blob> {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
    
          reader.onload = function () {
            const arrayBuffer = this.result as ArrayBuffer;
            const view = new DataView(arrayBuffer);
    
            // Read WAV header
            // const wavDecoder = lamejs.WavHeader.readHeader(new DataView(arrayBuffer));
            // Parse WAV header manually
            const numChannels = view.getUint16(22, true);    // Number of channels (mono/stereo)
            const sampleRate = view.getUint32(24, true);     // Sample rate (44100 Hz, etc.)
            const bitsPerSample = view.getUint16(34, true);  // Bits per sample (e.g., 16-bit)
            const dataOffset = 44;                           // Data starts at byte 44
            const dataLen = view.getUint32(40, true);        // Subchunk2Size (audio data length)
    
    
            // Extract PCM samples
            const wavSamples = new Int16Array(arrayBuffer, dataOffset, dataLen / (bitsPerSample / 8));
    
            // Create MP3 encoder
            const mp3Encoder = new lamejs.Mp3Encoder(numChannels, sampleRate, 128);
    
            // Encode WAV samples to MP3
            const mp3Buffer = mp3Encoder.encodeBuffer(wavSamples);
            const mp3Data = mp3Encoder.flush();
    
            // Combine MP3 buffers
            const mp3BufferWithHeader = new Uint8Array(mp3Buffer.length + mp3Data.length);
            mp3BufferWithHeader.set(mp3Buffer, 0);
            mp3BufferWithHeader.set(mp3Data, mp3Buffer.length);
    
            // Create Blob from MP3 data
            const mp3Blob = new Blob([mp3BufferWithHeader], { type: 'audio/mpeg' });
    
            resolve(mp3Blob);
          };
    
          reader.onerror = function (error) {
            reject(error);
          };
    
          reader.readAsArrayBuffer(wavBlob); // Read WAV blob as ArrayBuffer
        });
    }

    async convertToWav(audioBlob: Blob) {
        const arrayBuffer = await audioBlob.arrayBuffer();
        const audioContext = new AudioContext();
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

        const wavBlob = this.encodeWAV(audioBuffer);
        this.audioURL = URL.createObjectURL(wavBlob);
        return wavBlob;
    }

    encodeWAV(audioBuffer: AudioBuffer): Blob {
        const numberOfChannels = audioBuffer.numberOfChannels;
        const sampleRate = audioBuffer.sampleRate;
        const numSamples = audioBuffer.length * numberOfChannels;
        const data = new Float32Array(numSamples);

        // Interleave audio data for each channel
        for (let channel = 0; channel < numberOfChannels; channel++) {
            const channelData = audioBuffer.getChannelData(channel);
            for (let i = 0; i < channelData.length; i++) {
                data[i * numberOfChannels + channel] = channelData[i];
            }
        }

        // Create WAV file
        const wavBuffer = this.createWavFile(data, numberOfChannels, sampleRate);
        return new Blob([wavBuffer], { type: 'audio/wav' });
    }

    createWavFile(data: Float32Array, numChannels: number, sampleRate: number): ArrayBuffer {
        const bitsPerSample = 16;
        const byteRate = (sampleRate * numChannels * bitsPerSample) / 8;
        const blockAlign = (numChannels * bitsPerSample) / 8;
        const wavSize = data.length * (bitsPerSample / 8) + 44;

        const buffer = new ArrayBuffer(wavSize);
        const view = new DataView(buffer);

        // RIFF chunk descriptor
        this.writeString(view, 0, 'RIFF');
        view.setUint32(4, 36 + data.length * (bitsPerSample / 8), true);
        this.writeString(view, 8, 'WAVE');

        // FMT sub-chunk
        this.writeString(view, 12, 'fmt ');
        view.setUint32(16, 16, true); // Subchunk1Size (PCM)
        view.setUint16(20, 1, true); // AudioFormat (1 = PCM)
        view.setUint16(22, numChannels, true); // NumChannels
        view.setUint32(24, sampleRate, true); // SampleRate
        view.setUint32(28, byteRate, true); // ByteRate
        view.setUint16(32, blockAlign, true); // BlockAlign
        view.setUint16(34, bitsPerSample, true); // BitsPerSample

        // Data sub-chunk
        this.writeString(view, 36, 'data');
        view.setUint32(40, data.length * (bitsPerSample / 8), true);

        // Write PCM data
        let offset = 44;
        for (let i = 0; i < data.length; i++) {
            const sample = Math.max(-1, Math.min(1, data[i]));
            view.setInt16(offset, sample < 0 ? sample * 0x8000 : sample * 0x7FFF, true);
            offset += 2;
        }

        return buffer;
    }

    writeString(view: DataView, offset: number, string: string) {
        for (let i = 0; i < string.length; i++) {
            view.setUint8(offset + i, string.charCodeAt(i));
        }
    }

    sendVoiceMessage() {
        this.messageLoader = true;
        const mobilePhone = this.modelData?.mobile_phone;
        if (this.audioBlob && mobilePhone && Array.isArray(mobilePhone) && mobilePhone.length) {
            if (this.audioBlob.size > 0 && this.audioBlob.size <= 16 * 1024 * 1024) {
              let audioFile = new File([this.audioBlob], 'voice-message.mp3', { type: 'audio/mpeg' });
              audioFile = this.renameFileWithTimestamp(audioFile);

              const dataObj = {
                id: this.modelData?._id,
                mainFolder: 'whatsapp',
                model: 'accounts',
                userId: this.userRole?._id,
                userOffice: this.currentOffice._id,
              };

              if(audioFile && audioFile.size > 0) {
                this._baseService.uploadImage('comments/upload-audio-file', dataObj, audioFile, 'yii', 'file')
                .subscribe((response: any) => {
                  if (response && response?.body) {
                    const postData = {
                      account: {
                        modelId: this.modelData?._id,
                        agency: this.userAgency?._id,
                        user_office: this.currentOffice._id,
                        userId: this.userRole._id,
                        assigned_to_ids: this.modelData?.assigned_to_ids
                      },
                      voice: true,
                      to: mobilePhone,
                      message: { content: '', template: 'audio' },
                      fileName : response.body?.fileName,
                      audioWaId: response.body?.auidoId // WhatsApp media ID returned from backend
                    };

                    this._baseService.post('external-service/wa-message', postData, 'nodejs')
                      .subscribe((data: any) => {
                        this.messageLoader = false;
                        if (data?.status === 200) {
                        } else {
                          this._toaster.error(this._translateService.instant(data.message));
                        }
                      }, (error) => {
                        this.messageLoader = false;
                        console.error('Error sending WhatsApp message', error);
                      });
                  }
                  this.audioURL = null;
                }, (error) => {
                  console.error('Error uploading audio file', error);
                });
              }
            }else {
                console.error('Audio Blob is empty, cannot send file');
            }
        }else {
            this._toaster.warning(this._translateService.instant('Make sure mobile phone exists.'), this._translateService.instant('Warning'));
            this.messageLoader = false;
        }
    }

    getAudioUrl(message: any): string {
        if (message?.wa_audio_id && message.wa_audio_id.includes(".mp3")) { // as from CRM we sending MP3
          return `${this.env.cdnUrl}/whatsapp/accounts/${message.model_id}/${message.wa_audio_id}`;
        }else {
          return `${this.env.cdnUrl}/whatsapp/accounts/${message.model_id}/${message.wa_audio_id}.ogg`;
        }
    }
}
