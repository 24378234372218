import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({
    providedIn: 'root'
})
export class AttachmentsService {

    currentUser: any;
    constructor(
        private http: HttpClient,
        private _authenticationService: AuthenticationService
    ) {
        this.currentUser = this._authenticationService.currentUserValue;
    }

    saveModelAttachments(modelId: any, modelName: any, file: File, status: any, attachmentOrder: any, viewType: any, idNumber: any = '', lang: String = '', replaceFile: boolean = false, attachmentId = '', currentUserId: any = ''): Observable<any> {
        const formData: any = new FormData();
        formData.append('id', modelId);
        formData.append('file', file);
        formData.append('attachmentOrder', attachmentOrder);
        if(replaceFile && attachmentId){
            formData.append('replaceFile', replaceFile);
            formData.append('attachmentId', attachmentId);
        }
        if (modelName === 'tickets-attachments') {
            formData.append('modelName', 'tickets_documents');
            formData.append('internal_attachment', status);
            formData.append('user_id', currentUserId);
            return this.http.post(environment.yiiUrl + 'tickets-attachments/uploader', formData);
        }
        if (modelName === 'cms_medias') {
            formData.append('modelName', modelName);
            formData.append('user_id', currentUserId);
            return this.http.post(environment.yiiUrl + 'cms-medias/uploader', formData);
        }
        if(modelName == 'commercial_auction'){
            formData.append('identification_number',idNumber) ;
            formData.append('identification_type',modelName) ;
            formData.append('language',lang) ;
            formData.append('created_by', currentUserId);
        }
        if (viewType === 'propertyDocuments') {
            formData.append('document', true);
        }
        formData.append('modelName', modelName);
        return this.http.post(environment.yiiUrl + 'attachments/uploader', formData);
    }

    saveAttachmentData(data){
        return this.http.post(environment.yiiUrl + 'attachments/save-attachment-data', data);
    }

    unlinkAttachment(data){
        return this.http.post(environment.yiiUrl + 'attachments/unlink-attachment', data);
    }

    mailAttachment(file: File, directory='', modelId = ''): Observable<any> {
        const formData: any = new FormData();
        formData.append('file', file);
        if(directory != ''){
            formData.append('directory', directory);
        }
        if(modelId != ''){
            formData.append('model_id', modelId);
        }
        return this.http.post(environment.yiiUrl + 'emails/mail-attachment', formData);
    }

    getModelAttachments(id: any, modelName: any, documentKey = ""): Observable<any> {
        if(documentKey) {
            return this.http.get(environment.yiiUrl + `attachments/get-attachments?id=${id}&modelName=${modelName}&documentKey=${documentKey}&expand=document_key`);
        }else {
            return this.http.get(environment.yiiUrl + `attachments/get-attachments?id=${id}&modelName=${modelName}&expand=document_key`);
        }
    }
    getPropertyBrochures(id: any, modelName: any): Observable<any> {
        return this.http.get(environment.yiiUrl + `attachments/get-property-brochures?id=${id}&modelName=${modelName}`);
    }
    getPaginatedModelAttachments(id: any, modelName: any, perPage: any, page: any): Observable<any> {
        // tslint:disable-next-line: max-line-length
        return this.http.get(environment.yiiUrl + `attachments/get-paginated-attachments?id=${id}&modelName=${modelName}&expand=document_key&page=${page}&per-page=${perPage}`, { observe: 'response' as 'body' });
    }
    getPropertiesAttachments(id: any): Observable<any> {
        return this.http.get(environment.yiiUrl + `properties/get-attachments?p_id=${id}`);
    }

    getTicketsAttachments(id: any): Observable<any> {
        return this.http.get(environment.yiiUrl + `tickets-attachments/get-attachments?id=${id}&modelName=tickets_documents`);
    }

    getConstructionsAttachments(id: any): Observable<any> {
        return this.http.get(environment.yiiUrl + `attachments/get-attachments?id=${id}&modelName=constructions_images`);
    }

    getBrochuresAttachments(id: any): Observable<any> {
        return this.http.get(environment.yiiUrl + `attachments/get-attachments?id=${id}&modelName=brochures_images`);
    }

    saveCompaniesCSV(file: File, _id: string): Observable<any> {
        const formData: any = new FormData();
        formData.append('file', file);
        return this.http.post(environment.yiiUrl + 'xml-export-formats/csv-company-uploader?user=' + _id, formData);
    }

    saveContactsCSV(file: File, user: string, company: string): Observable<any> {
        const formData: any = new FormData();
        formData.append('file', file);
        return this.http.post(environment.yiiUrl + 'xml-export-formats/csv-contacts-uploader?user=' + user + '&company=' + company, formData);
    }

    saveOwnersCSV(file: File, _id: string): Observable<any> {
        const formData: any = new FormData();
        formData.append('file', file);
        return this.http.post(environment.yiiUrl + 'xml-export-formats/csv-owner-uploader?user=' + _id, formData);
    }

    saveTimelineCSV(file: File, _id: string): Observable<any> {
        const formData: any = new FormData();
        formData.append('file', file);
        return this.http.post(environment.yiiUrl + 'xml-export-formats/csv-timeline-uploader?user=' + _id, formData);
    }

    deleteAttachment(id: any): Observable<any> {
        return this.http.delete(`${environment.yiiUrl}attachments/${id}`);
    }

    deleteCmsMedia(id: any, file: any): Observable<any> {
        return this.http.delete(`${environment.yiiUrl}cms-medias/delete-attachment?model_id=${id}&file_name=${file}`);
    }

    updateAttachment(data: any): Observable<any> {
        return this.http.put(`${environment.yiiUrl}attachments/${data._id}`, data);
    }

    updateBrochureAttachment(data: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}attachments/update-brochures-images?id=${data._id}&order=${data.order}`);
    }

    uploadSingle(madelId: any, modelName: any, file: File): Observable<any> {
        const formData: any = new FormData();
        formData.append('id', madelId);
        formData.append('model', modelName);
        formData.append('file', file);
        return this.http.post(`${environment.yiiUrl}users/upload-single`, formData);
    }

    uploadTranslations(file: File): Observable<any> {
        const formData: any = new FormData();
        formData.append('file', file);
        return this.http.post(environment.yiiUrl + 'translations/uploade-translations', formData);
    }
    copyAttachments(from: any, to: any, construction : boolean = false): Observable<any> {
        return this.http.get<any[]>(`${environment.yiiUrl}attachments/copy-attachment?from_id=${from}&to_id=${to}&construction=${construction}`);
    }

    getCmsMedia(id: any, model: any, page: any): Observable<any> {
        return this.http.get<any[]>(`${environment.yiiUrl}cms-medias/get-cms-medias?id=${id}&modelName=${model}&page=${page}`, { observe: 'response' as 'body' });
    }

    getImageURL(model: string, id: string, imageName: string, cdnUrl?:string): Observable<object> {
        if(!cdnUrl || cdnUrl != '')
            cdnUrl = environment?.cdnUrl ? environment.cdnUrl : '';
        return this.http.get(`${environment.yiiUrl}attachments/get-image-url?model_id=${id}&model=${model}&name=${imageName}&cdnUrl=${cdnUrl}&src=true`);
    }
}
