import { Pipe, PipeTransform } from '@angular/core';
import { AuthenticationService } from 'app/services';

@Pipe({
  name: 'GetConstructionReference'
})
export class GetConstructionReferencePipe implements PipeTransform {

  constructor(
    private _authenticationService: AuthenticationService,
  ) { }

  transform(construction: any, args?: any): any {
    const agency = this._authenticationService.currentAgencyValue;
    let reference = construction?.reference;
    if (agency?.pdf_external_ref === 'external_reference' && typeof construction?.user_reference !== 'undefined' && construction?.user_reference) {
      reference = construction.user_reference;
    }
    if (agency?.pdf_external_ref === 'agency_reference' && typeof construction?.agency_reference !== 'undefined' && construction?.agency_reference) {
      reference = construction.agency_reference;
    }
    return reference;
  }
}