import { Component, OnInit, ChangeDetectorRef, AfterViewChecked, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { Observable, merge } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { TasksService, DropdownsService, AuthenticationService,BookingsService } from 'app/services';
import { startWith, tap, switchMap, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { forEach, includes, map, cloneDeep, isEmpty, filter, find } from 'lodash';
import { GlobalFuntions } from 'app/_helpers';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { CommonDialogComponent } from 'app/components/common-dialog/common-dialog.component';
import { TimeLineDataService } from 'app/components/time-line/time-line-data.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TasksCreateModalComponent } from 'app/main/activities/tasks/tasks-create-modal/tasks-create-modal.component';
import { CrmMenuService } from 'app/services/crm-menu.service';
import { GetCommercialReferencePipe } from 'app/pipes/get-commercial-reference.pipe';

@Component({
    selector: 'app-tasks-create-form',
    templateUrl: './tasks-create-form.component.html',
    styleUrls: ['./tasks-create-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [GetCommercialReferencePipe]
})
export class TasksCreateFormComponent implements OnInit, AfterViewChecked {

    @Output() openTimeline: EventEmitter<any>;
    @Input() modal: any;
    public searching: boolean;
    date = new Date().toISOString();
    offices: any;
    searchOffices$ = new Subject<string>();
    id: any;
    contactSearch: FormControl = new FormControl();
    searchAssignedTo$ = new Subject<string>();
    contacts: any;
    activity: FormGroup;
    viewRecord: boolean;
    tasks: any;
    filter: any;
    user: any;
    officeData: any;
    users: any;
    chiltItems: Observable<any[]>;
    task: FormArray;
    accounts: any;
    owners: any;
    properties: any;
    companies: any;
    projects: any;
    urbanisations: any;
    sales: any;
    commercials: any;
    bookings: any;
    rentals: any;
    mooringsData: any;
    boatsData: any;
    projectSearch$ = new Subject<string>();
    urbanisationSearch$ = new Subject<string>();
    salesSearch$ = new Subject<string>();
    commercialSearch$ = new Subject<string>();
    mooringSearch$ = new Subject<string>();
    boatSearch$ = new Subject<string>();
    bookingSearch: FormControl = new FormControl();
    bookingSearch$ = new Subject<string>();
    rentalSearch$ = new Subject<string>();
    accountSearch$ = new Subject<string>();
    propertySearch$ = new Subject<string>();
    ownerSearch$ = new Subject<string>();
    companySearch$ = new Subject<string>();
    currentAgency: any;
    systemLang: any;

    private _unsubscribeAll: Subject<any>;
    relatedToOptions: { value: string; label: string; }[];
    constructor(
        private router: Router,
        private _tasksService: TasksService,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private _matDialog: MatDialog,
        private _dropdownsService: DropdownsService,
        private _authenticationService: AuthenticationService,
        private cdRef: ChangeDetectorRef,
        public _globalFuntions: GlobalFuntions, // for dateChange
        private _translateService: TranslateService,
        private _timeLineData: TimeLineDataService,
        private _fuseSidebarService: FuseSidebarService,
        public matDialogRef: MatDialogRef<TasksCreateModalComponent>,
        public _crmMenuService: CrmMenuService,
        private reference: GetCommercialReferencePipe,
        private _bookingsService: BookingsService,

    ) {
        this._translateService.onLangChange.pipe(startWith('')).subscribe(() => {
            this.systemLang = this._translateService.currentLang === 'es' ? 'es_AR' : this._translateService.currentLang;
        });
        this.openTimeline = new EventEmitter();
        this.user = this._authenticationService.currentUserValue;
        this.officeData = this._authenticationService.currentOfficeValue;
        this.filter = {};
        this.filter.options = {
            page: 1,
            limit: 1000,
            sort: {
                value: 1
            }
        };
        this.filter.query = {
            status: 'Active'
        };
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.relatedToData();
        this._dropdownsService.getTaskTypes(this.filter).subscribe((data: any) => {
            this.tasks = data.docs;
        });
        // this._dropdownsService.getUsersByAgencies().subscribe((data: any) => {
        //   this.users = data.body;
        //   this.chiltItems = data.body;
        // });
        this.searchAssignedTo$
            .pipe(
                startWith(''),
                distinctUntilChanged(),
                takeUntil(this._unsubscribeAll),
                switchMap((search: string) => {
                    return this._dropdownsService.getAllAgencyUsers(search);
                }),
            )
            .subscribe((data: any) => {
                console.log(data);
                _.forEach(data, (value) => {
                    value.label = _.isEmpty(_.trim(value.full_name)) ? value.username : value.full_name;
                });
                this.users = data;
                this.chiltItems = data;
            });
        this.viewRecord = false;
        let officeRecord = false;
        if (includes(this.router.url, 'view')) {
            this.viewRecord = true;
            officeRecord = true;
        }

        if (this._globalFuntions.getUserRole(this.user.user_role)) {
            officeRecord = true;
        }

        this.activity = this.formBuilder.group({
            _id: [],
            subject: [{ value: '', disabled: this.viewRecord }, Validators.required],
            private: [{ value: '', disabled: this.viewRecord }],
            priority: [{ value: 'Normal', disabled: this.viewRecord }],
            status: [{ value: 'Open', disabled: this.viewRecord }],
            start_date: [{ value: this.date, disabled: this.viewRecord }],
            start_time: [{ value: this.date, disabled: this.viewRecord }],
            end_date: [{ value: this.date, disabled: this.viewRecord }],
            end_time: [{ value: this.date, disabled: this.viewRecord }],
            notification: this.formBuilder.group({
                type: [{ value: 'email', disabled: this.viewRecord }],
                duration: [{ value: '30', disabled: this.viewRecord }],
                time: [{ value: 'minutes', disabled: this.viewRecord }],
            }),
            assignee_user: [{ value: 'None', disabled: this.viewRecord }],
            calendar_id: [{ value: 'None', disabled: this.viewRecord }],
            dependent_step: [{ value: 'None', disabled: this.viewRecord }],
            m_end: [],
            m_start: [],
            milestone: [{ value: 'None', disabled: this.viewRecord }],
            related_steps: [{ value: 'None', disabled: this.viewRecord }],
            sequence_id: [{ value: 'None', disabled: this.viewRecord }],
            steps: [{ value: 'None', disabled: this.viewRecord }],
            repeate_type: [{ value: 'None', disabled: this.viewRecord }],
            description: [{ value: '', disabled: this.viewRecord }],
            offices: [{ value: [this.officeData._id], disabled: officeRecord }, Validators.required],
            task_type: [{ value: '', disabled: this.viewRecord }],
            from: [{ value: this.user.user_name, disabled: this.viewRecord }],
            assignee: [{ value: [this.user._id], disabled: this.viewRecord }],
            task: this.formBuilder.array([this.createTask()]),
            contacts: [{ value: '', disabled: this.viewRecord }],
            task_type_value: this.formBuilder.group({
                en: (''),
                es_AR: ('')
            }),
            type: [{ value: 'task', disabled: false }],
            created_at: [{ value: '', disabled: false }],
            created_by: [{ value: '', disabled: false }],
            agency: [{ value: '', disabled: false }],
            color: [{ value: '', disabled: false }],
            task_to_call: [{ value: false, disabled: false }], 
        });

        this.task = this.activity.get('task') as FormArray;
        this.currentAgency = this._authenticationService.currentAgencyValue;

        this.searchOffices$
            .pipe(
                startWith(''),
                switchMap((search: string) => {
                    return this._dropdownsService.getMeetingOffices(search);
                }),
            )
            .subscribe((data: any) => {
                this.offices = data.body;
            });

        merge(this.contactSearch.valueChanges)
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap(() => {
                    let search = this.contactSearch.value;
                    if (this.contactSearch.value) {
                        search = this.contactSearch.value;
                    }
                    return this._dropdownsService.getParticipants(search);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.contacts = data.body;
            });

        this.accountSearch$
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap((searchValue: string) => {
                    let search = '';
                    if (searchValue) {
                        search = searchValue;
                    }
                    return this._dropdownsService.getAccounts(search);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                const accounts = map(data.body, (element) => {
                    if (element.type === 'company') {
                        element.label = element.company_name;
                    } else {
                        element.label = element.full_name;
                    }
                    return element;
                });
                this.accounts = accounts;
            });

        this.ownerSearch$
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap((searchValue: string) => {
                    let search = '';
                    if (searchValue) {
                        search = searchValue;
                    }
                    return this._dropdownsService.getOwners(search, '', false);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                const owners = map(data.body, (element) => {
                    if (element.type == 'company') {
                        element.label = element.company_name;
                    } else {
                        element.label = element.full_name;
                    }
                    return element;
                });
                this.owners = owners;
            });

        this.propertySearch$
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap((search: string) => {
                    return this._dropdownsService.getProperty(search);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                const properties = map(data.body, (property) => {
                    if (this.currentAgency.pdf_external_ref === 'external_reference' && property.agency_reference) {
                        property.label = property.agency_reference;
                    } else if (this.currentAgency.pdf_external_ref === 'agency_reference' && property.external_reference) {
                        property.label = property.external_reference;
                    } else if (this.currentAgency.pdf_external_ref === 'reference') {
                        property.label = property.reference;
                    } else {
                        property.label = property.reference;
                    }
                    if (property.type_one) {
                        property.label = property.label + ' - ' + property.type_one;
                    }
                    if (property.location) {
                        property.label = property.label + ' - ' + property.location;
                    }
                    return property;
                });
                this.properties = properties;
            });
        this.commercialSearch$
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap((search: string) => {
                    const searchFilter = {} as any;
                    if (search) {
                        const queryOr = [];
                        queryOr.push({ reference: Number(search) });
                        queryOr.push({ external_reference: { $regex: '.*' + search + '.*', $options: 'i' } });
                        queryOr.push({ other_reference: { $regex: '.*' + search + '.*', $options: 'i' } });
                        searchFilter.$or = queryOr;
                    }
                    return this._dropdownsService.getCommercialProperties(searchFilter);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                if(data) {
                    for(const value of data.body) {
                        value.label = this.reference.transform(value) + 
                        ((value?.type_one_value) ?
                        ( ' - ' + value?.type_one_value[this.systemLang]) : '') + 
                        ((value?.location_value) ?
                        (' - ' + value?.location_value[this.systemLang]) : '')
                    }
                    this.commercials = data.body;
                }
            });

            this.mooringSearch$
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap((search: string) => {
                    const filter = {
                        query: {
                            archived: { $ne: true },
                        } as any,
                        options: {} as any,
                    } as any;
                    if (search) {
                        const queryOr = [];
                        queryOr.push({
                            reference: Number(search),
                        });
                        queryOr.push({
                            type_one: {
                                $regex:
                                    '.*' + search + '.*',
                                $options: 'i',
                            },
                        });
                        // queryOr.push({
                        //     property_city: {
                        //         $regex:
                        //             '.*' + search + '.*',
                        //         $options: 'i',
                        //     },
                        // });
                        filter.query.$or = queryOr;
                    }
                    return this._dropdownsService.getMoorings('mooring_properties', filter);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.mooringsData = data.body;
            });

            this.boatSearch$
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap((search: string) => {
                    const filter = {
                        query: {
                            archived: { $ne: true },
                        } as any,
                        options: {} as any,
                    } as any;   
                    if (search) {
                        const queryOr = [];
                        queryOr.push({
                            reference: Number(search),
                        });
                        queryOr.push({
                            type_one: {
                                $regex:
                                    '.*' + search + '.*',
                                $options: 'i',
                            },
                        });
                        // queryOr.push({
                        //     property_city: {
                        //         $regex:
                        //             '.*' + search + '.*',
                        //         $options: 'i',
                        //     },
                        // });
                        filter.query.$or = queryOr;
                    }
                    return this._dropdownsService.getMoorings('boats', filter);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.boatsData = data.body;
            });

        this.companySearch$
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap((search: string) => {
                    return this._dropdownsService.getCompany(search);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.companies = data.body;
            });

        this.projectSearch$
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap((search: string) => {
                    return this._dropdownsService.getProject(search);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                const projects = map(data.body, (project) => {
                    project.label = this.user.user_agency_data.agency_reference;
                    if (project.reference) {
                        project.label = project.label + ' - ' + project.reference;
                    }
                    if (project.user_reference) {
                        project.label = project.label + ' / ' + project.user_reference;
                    }
                    if (project.agency_reference) {
                        project.label = project.label + ' / ' + project.agency_reference;
                    }
                    return project;
                });
                this.projects = projects;
            });

        this.urbanisationSearch$
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap((search: string) => {
                    return this._dropdownsService.getUrbanisation(search);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.urbanisations = data.docs;
            });

        this.salesSearch$
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap((search: string) => {
                    return this._dropdownsService.getSale(search);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                const sales = map(data.body, (sale) => {
                    sale.label = this.user.user_agency_data.agency_reference;
                    if (sale.property_data) {
                        sale.label = sale.label + ' ' + sale.property_data.reference;
                        if (sale.property_data.type_one_name) {
                            sale.label = sale.label + ' ' + sale.property_data.type_one_name;
                        }
                        if (sale.property_data.location_name) {
                            sale.label = sale.label + ' ' + this._translateService.instant('in') + ' ' + sale.property_data.location_name;
                        }
                    }
                    return sale;
                });
                this.sales = sales;
            });

        this.rentalSearch$
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap((search: string) => {
                    return this._dropdownsService.getRental(search);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                const rentals = map(data.body, (rental) => {
                    rental.label = rental.contract_id;
                    if (rental.property_data) {
                        rental.label = rental.label + ' / ' + rental.property_data.reference;
                    }
                    return rental;
                });
                this.rentals = rentals;
            });

        this.bookingSearch$
            .pipe(
              startWith(''),
              tap(() => this.searching = true),
              switchMap(() => {
                this.filter.sort = {};
                return this._bookingsService.collectionSearch(this.filter, 1);
              })
            )
            .subscribe((data: any) => {
                this.searching = false;
                const bookings = _.map(data?.data, (booking) => {
                    // Create an array of labels removing undefined, null, and falsy values
                    const labels = [booking.reference, booking.property_reference, booking.client_name].filter(Boolean);
                    // Join the label parts with a separator and assign to the booking's label property
                    booking.label = labels.join(' - ');
                    return booking;
                });
                this.bookings = bookings;
            });

        this.id = this.route.snapshot.paramMap.get('id');
        if (this.id) {
            this._tasksService.getById(this.id).subscribe((data: any) => {
                // remove null values
                data = Object.entries(data).reduce((task, [key, value]) => {
                    if (value !== null) {
                      task[key] = value;
                    }
                    return task;
                }, {});
                this.activity.patchValue(data);
                this.patchFields(data);
            });
        }
        // Ticket # 1512
        // if (!this.id) {
        //     this.activity.get('end_time').valueChanges
        //         .subscribe((endTime: any) => {
        //             if (this.activity.get('start_date').value === this.activity.get('end_date').value) {
        //                 if (endTime instanceof Date) {
        //                     const date = cloneDeep(endTime);
        //                     date.setHours(date.getHours() - 1);
        //                     this.activity.get('start_time').setValue(date);
        //                 }
        //             }
        //         });
        // }
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }
    relatedToData() {
        this.relatedToOptions = [
            { value: 'Account', label: 'Account' },
            { value: 'Owner', label: 'Owner' },
            { value: 'Company', label: 'Company' },
            { value: 'Project', label: 'Project' },
            { value: 'Urbanisation', label: 'Urbanisation' },
            { value: 'Sale', label: 'Sale' },
        ];
        if (this._crmMenuService.isPropertyModule) {
            this.relatedToOptions.push({ value: 'Property', label: 'Property' });
        }
    
        if (this._crmMenuService.isCommercialModule) {
            this.relatedToOptions.push({ value: 'Commercial Properties', label: 'Commercial Properties' });
        }
    
        if (this._crmMenuService.isCommHolidayRentalModule) {
            this.relatedToOptions.push({ value: 'Booking', label: 'Booking' });
        }
    
        if (this._crmMenuService.isLongTermRentalModule) {
            this.relatedToOptions.push({ value: 'Rental Contracts', label: 'Rental Contracts' });
        }
    
        if (this._crmMenuService.isMooringModule) {
            this.relatedToOptions.push({ value: 'Moorings', label: 'Moorings' });
        }
    
        if (this._crmMenuService.isBoatModule) {
            this.relatedToOptions.push({ value: 'Boats', label: 'Boats' });
        }
        this.relatedToTranslateLabels();
    }

    relatedToTranslateLabels() {
        this.relatedToOptions.forEach(option => {
            option.label = this._translateService.instant(option.label);
        });
        this.relatedToOptions.sort((a, b) => a.label.localeCompare(b.label));
    }

    patchFields(data: any): void {
        this.clearFormArray(this.task);
        if (data.task && data.task.length) {
            forEach(data.task, (extra, key) => {
                this.task.push(this.createTask());
                if (extra.related_to === 'Property') {
                    extra.related_to = 'Commercial Properties';
                    extra.commercial_property = extra.property;
                }
                this.activity.get('task.' + key).patchValue(extra);
                if (extra.related_to === 'Account') {
                    this.loadAccount(extra.account);
                }
                if (extra.related_to === 'Owner') {
                    this.loadOwner(extra.owner);
                }
                if (extra.related_to === 'Property') {
                    this.loadProperty(extra.property);
                }
                if (extra.related_to === 'Company') {
                    this.loadCompany(extra.company);
                }
                if (extra.related_to === 'Project') {
                    this.loadProject(extra.project);
                }
                if (extra.related_to === 'Urbanisation') {
                    this.loadUrbanisation(extra.urbanisation);
                }
                if (extra.related_to === 'Sale') {
                    this.loadSales(extra.sale);
                }
                if (extra.related_to === 'Commercial Properties') {
                    this.loadCommercial(extra.commercial_property);
                }
                if (extra.related_to === 'Rental Contracts') {
                    this.loadRentalContract(extra.rental_contracts);
                }
                if (extra.related_to === 'Moorings') {
                    this.loadMoorings(extra.mooring);
                }
                if (extra.related_to === 'Boats') {
                    this.loadBoats(extra.boat);
                }
            });
        } else {
            this.task.push(this.createTask());
        }
    }

    loadAccount(_id: string): void {
        this._dropdownsService.getAccounts('', _id)
            .pipe(tap(() => this.searching = true))
            .subscribe((data: any) => {
                this.searching = false;
                const accounts = map(data.body, (element) => {
                    if (element.type === 'company') {
                        element.label = element.company_name;
                    } else {
                        element.label = element.full_name;
                    }
                    return element;
                });
                this.accounts = accounts;
            });
    }

    loadOwner(_id: string): void {
        this._dropdownsService.getOwners('', _id)
            .pipe(tap(() => this.searching = true))
            .subscribe((data: any) => {
                this.searching = false;
                const owners = map(data.body, (element) => {
                    if (element.type == 'company') {
                        element.label = element.company_name;
                    } else {
                        element.label = element.full_name;
                    }
                    return element;
                });
                this.owners = owners;
            });
    }

    loadProperty(_id: string): void {
        this._dropdownsService.getProperty('', '', _id)
            .pipe(tap(() => this.searching = true))
            .subscribe((data: any) => {
                const properties = map(data.body, (property) => {
                    property.label = property.reference;
                    if (property.agency_reference) {
                        property.label = property.label + ' - ' + property.agency_reference;
                    }
                    if (property.type_one) {
                        property.label = property.label + ' - ' + property.type_one;
                    }
                    if (property.type_one) {
                        property.label = property.label + ' - ' + property.location;
                    }
                    return property;
                });
                this.properties = properties;
            });
    }

    loadCompany(_id: string): void {
        this._dropdownsService.getCompany('', _id)
            .pipe(tap(() => this.searching = true))
            .subscribe((data: any) => {
                this.searching = false;
                this.companies = data.body;
            });
    }

    loadProject(_id: string): void {
        this._dropdownsService.getProject('', _id)
            .pipe(tap(() => this.searching = true))
            .subscribe((data: any) => {
                this.searching = false;
                const projects = map(data.body, (project) => {
                    project.label = this.user.user_agency_data.agency_reference;
                    if (project.reference) {
                        project.label = project.label + ' - ' + project.reference;
                    }
                    if (project.user_reference) {
                        project.label = project.label + ' / ' + project.user_reference;
                    }
                    if (project.agency_reference) {
                        project.label = project.label + ' / ' + project.agency_reference;
                    }
                    return project;
                });
                this.projects = projects;
            });
    }

    loadUrbanisation(key: string): void {
        this._dropdownsService.getUrbanisation('', key)
            .pipe(tap(() => this.searching = true))
            .subscribe((data: any) => {
                this.searching = false;
                this.urbanisations = data.docs;
            });
    }

    loadSales(_id: string): void {
        this._dropdownsService.getSale(null, _id)
            .pipe(tap(() => this.searching = true))
            .subscribe((data: any) => {
                this.searching = false;
                this.searching = false;
                const sales = map(data.body, (sale) => {
                    sale.label = this.user.user_agency_data.agency_reference;
                    if (sale.property_data) {
                        sale.label = sale.label + ' ' + sale.property_data.reference;
                        if (sale.property_data.type_one_name) {
                            sale.label = sale.label + ' ' + sale.property_data.type_one_name;
                        }
                        if (sale.property_data.location_name) {
                            sale.label = sale.label + ' ' + this._translateService.instant('in') + ' ' + sale.property_data.location_name;
                        }
                    }
                    return sale;
                });
                this.sales = sales;
            });
    }

    loadCommercial(_id: string): void {
        const searchFilter = {} as any;
        searchFilter.$or = [{ _id: _id  }];

        this._dropdownsService.getCommercialProperties(searchFilter)
            .pipe(tap(() => this.searching = true))
            .subscribe((data: any) => {
                this.searching = false;
                if(data) {
                    for(const value of data.body) {
                      value.label = this.reference.transform(value) + 
                          ((value?.type_one_value) ?
                              ( ' - ' + value?.type_one_value[this.systemLang]) : '') + 
                          ((value?.location_value) ?
                          (' - ' + value?.location_value[this.systemLang]) : '')
                    }
                    this.commercials = data.body;
                }
            });
    }

    
    loadMoorings(_id: string): void {
        const searchFilter = {} as any;
        searchFilter.$and = [{ _id: { $in: _id } }];

        this._dropdownsService.getMoorings('mooring_properties', searchFilter)
            .pipe(tap(() => this.searching = true))
            .subscribe((data: any) => {
                this.searching = false;
                this.mooringsData = data.body;
            });
    }

    
    loadBoats(_id: string): void {
        const searchFilter = {} as any;
        searchFilter.$and = [{ _id: { $in: _id } }];

        this._dropdownsService.getMoorings('boats', searchFilter)
            .pipe(tap(() => this.searching = true))
            .subscribe((data: any) => {
                this.searching = false;
                this.boatsData = data.body;
            });
    }

    loadRentalContract(_id: string): void {
        this._dropdownsService.getRental('', _id)
            .pipe(tap(() => this.searching = true))
            .subscribe((data: any) => {
                this.searching = false;
                const rentals = map(data.body, (rental) => {
                    rental.label = rental.contract_id;
                    if (rental.property_data) {
                        rental.label = rental.label + ' / ' + rental.property_data.reference;
                    }
                    return rental;
                });
                this.rentals = rentals;
            });
    }

    createTask(): FormGroup {
        return this.formBuilder.group({
            related_to: [{ value: '', disabled: this.viewRecord }],
            account: [{ value: '', disabled: this.viewRecord }],
            owner: [{ value: '', disabled: this.viewRecord }],
            property: [{ value: '', disabled: this.viewRecord }],
            company: [{ value: '', disabled: this.viewRecord }],
            project: [{ value: '', disabled: this.viewRecord }],
            urbanisation: [{ value: '', disabled: this.viewRecord }],
            sale: [{ value: '', disabled: this.viewRecord }],
            commercial_property: [{ value: '', disabled: this.viewRecord }],
            booking: [{ value: '', disabled: this.viewRecord }],
            rental_contracts: [{ value: '', disabled: this.viewRecord }],
            mooring: [{ value: '', disabled: this.viewRecord }],
            boat: [{ value: '', disabled: this.viewRecord }],
        });
    }

    clearFormArray = (formArray: FormArray) => {
        while (formArray.length !== 0) {
            formArray.removeAt(0);
        }
    }

    getTooltipHtmlPhones(related: any, id: any): any {
        let data: any;
        if (related === 'account') {
            data = find(this.accounts, ['_id', id]);
            // this._dropdownsService.getAccounts('', id).subscribe((res: any) => {
            //     data = res.body;
            // });
        } else if (related === 'owner') {
            data = find(this.owners, ['_id', id]);
            // this._dropdownsService.getOwners('', id).subscribe((res: any) => {
            //     data = res.body;
            // });
        }

        let html = '';
        // console.log(data);

        if (data && (data.home_phone) && data.home_phone !== '') {
            html += `${this._translateService.instant('home_phone')}: <a href="tel:${data.home_phone}">${data.home_phone}</a> `;
        }
        if (data && (data.work_phone) && data.work_phone !== '') {
            html += `${this._translateService.instant('work_phone')}: <a href="tel:${data.work_phone}">${data.work_phone}</a> `;
        }
        if (data && (data.mobile_phone) && data.mobile_phone !== '') {
            html += `${this._translateService.instant('mobile_phone')}: <a href="tel:${data.mobile_phone}">${data.mobile_phone}</a> `;

            html += `<br>`;

        }
        if (data && (data.mobile) && data.mobile !== '') {
            html += `${this._translateService.instant('mobile_phone')}: <a href="tel:${data.mobile}">${data.mobile}</a> `;
        }
        if (data && !isEmpty(data.partners) && data.partners.length) {
            forEach(data.partners, (partner) => {
                if (!isEmpty(partner.phone)) {
                    html += `${this._translateService.instant('Partners Phones')}: <a href="tel:${partner.phone}">${partner.phone}</a> <br>`;
                }
            });
        }
        return html;
    }

    openCommonDialog(related: any, id: any): any {
        let data: any;
        if (related === 'account') {
            data = find(this.accounts, ['_id', id]);
        } else if (related === 'owner') {
            data = find(this.owners, ['_id', id]);
        }
        const mobileData = {
            type: related,
            _id: data._id,
            mobile: related === 'account' ? data.mobile_phone : data.mobile,
        };
        const dialog = this._matDialog.open(CommonDialogComponent, {
            disableClose: false,
            panelClass: 'common-dialog',
            data: mobileData
        });
        dialog.afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((response: any) => {
                if (response) {
                    data.whatsapp = true;
                    this._timeLineData.setModelDataValue(data);
                    this._timeLineData.setRelatedToValue(related);
                    this._fuseSidebarService.getSidebar('timeline').toggleOpen(1000000);
                }
            });
    }
    goToAccount(id: any): any {
        if (id) {
            if (this.matDialogRef && typeof this.matDialogRef.close === 'function') {
                this.matDialogRef.close('refresh');
            }
            this.router.navigate(['/master-data/accounts/update/' + id]);
        }
    }

    hasContactAccess(id: string, relatedTo: string): boolean {
        let result = true;
        if (['agent', 'admin_agent_light'].includes(this.user.user_role)) {
            let email = this.user.hasOwnProperty('email') ? this.user.email : (this.user.hasOwnProperty('user_email') ? this.user.user_email : '');
            if (id) {
                let data: any;
                if (relatedTo == 'Account' && this.accounts) {
                    data = this.accounts.find(x => x._id == id);
                } else if (relatedTo == 'Owner' && this.owners) {
                    data = this.owners.find(x => x._id == id);
                }
                if (data) {
                    if (
                        data.hasOwnProperty('assign_agent') &&
                        Array.isArray(data.assign_agent) &&
                        data.assign_agent.length &&
                        data.assign_agent.includes(this.user._id)) {
                        result = true;
                    }
                    if (
                        data.hasOwnProperty('assigned_to') &&
                        Array.isArray(data.assigned_to) &&
                        data.assigned_to.length &&
                        data.assigned_to.includes(email)
                    ) {
                        result = true;
                    }
                }
            }
        }
        return result;
    }
}
